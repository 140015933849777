/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

/** Ionic CSS Variables **/
:root {
  --ion-font-family: "Outfit", sans-serif;
}

.p-compontent,
button,
p,
h1,
h2,
h3,
h4,
h5,
h6,
input,
textarea {
  font-family: var(--ion-font-family) !important;
}

.p-dialog-content {
  padding: 1rem !important;
}

.p-button {
  transition: all 0.15s ease-in-out !important;
}

.p-accordion .p-accordion-header .p-accordion-header-link,
.p-accordion-content {
  padding: 0 !important;
}

.p-accordion .p-accordion-tab {
  box-shadow: none !important;
}

.p-inputtext,
.p-dialog .p-dialog-footer {
  padding: 0.75rem 0.5rem !important;
}

.p-dropdown:not(disabled).p-focus {
  box-shadow: none !important;
}

.p-carousel-indicators {
  display: none !important;
}

.p-calendar-w-btn:not(.p-disabled).p-inputwrapper-focus {
  box-shadow: none !important;
  border-color: inherit !important;
}

.p-datepicker table td.p-datepicker-today > span {
  border-color: transparent !important;
  box-shadow: none !important;
}

.p-component {
  font-family: var(--ion-font-family), -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol" !important;
}

div.main_loader {
  height: 100%;
  width: 100vw;
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

a {
  color: inherit !important;
  text-decoration: none;
}

h1.fd-header {
  font-size: 2rem;
  font-weight: bold;
  margin: 6px 0px;
}

@media (prefers-reduced-motion) {
  body {
    animation: none;
  }
}

ion-content {
  /* --offset-bottom: 8vh !important; */
  padding-bottom: 7vh !important;
}
